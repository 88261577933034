<template>
  <ProjectLayout
    :displayedCard="'expired-technician-booking'"
  >
    <b-card
      align="left"
      class="booking-closed-card side-card"
      :class="{ 'has-bookings': areThereBookings }"
      header-tag="header"
      no-body
    >
      <template #header>
        <b-row>
          <b-col>
            <h5>Bookings vencidos</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="booking-wrapper" v-if="!areThereBookings">
              <div>
                <strong>¡Vaya!</strong>
              </div>
              Parece que no tienes bookings vencidos
            </div>
          </b-col>
        </b-row>
      </template>
      <b-card-body v-if="areThereBookings" class="booking-list">
        <b-container>
          <b-row v-for="booking in bookings" :key="booking.id">
            <b-col cols="2">
              <div class="booking-code">
                {{ booking.getCode }}
              </div>
            </b-col>
            <b-col cols="8">
              <div class="booking-name">{{ booking.getName }}</div>
              <div class="booking-event-date">{{ booking.getEventDate }}</div>
              <div class="booking-location">{{ booking.getShortLocation }}</div>
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
    </b-card>
  </ProjectLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProjectLayout from '@/views/project/layout/ProjectLayout.vue'

export default {
  name: 'ExpiredTechnicianBooking',
  components: {
    ProjectLayout
  },
  created () {
    this.getAll(this.$route.params.projectId)
  },
  computed: {
    ...mapGetters({ bookings: 'technicianBooking/expired' }),
    areThereBookings () {
      return this.bookings.length > 0
    }
  },
  methods: {
    ...mapActions('technicianBooking', ['getAll'])
  }
}
</script>

<style lang="less" scoped>
@import 'expiredTechnicianBooking';
</style>
